// Main Page Folders
export const PAGE_FOLDERS = {
    checkout: 'purchase',
    products: 'products',
    enterprise: 'enterprise',
};

// Valid Paths
export const VALID_PATHS = [
    '/_error',
    '/404',
    '/500',
    `/${PAGE_FOLDERS.checkout}`,
    `/${PAGE_FOLDERS.checkout}/paypal_capture`,
    `/${PAGE_FOLDERS.checkout}/payment_status`,
    `/${PAGE_FOLDERS.checkout}/threeds`,
    `/${PAGE_FOLDERS.checkout}/sofort`,
    `/${PAGE_FOLDERS.checkout}/ideal`,
    `/${PAGE_FOLDERS.checkout}/return_inicis`,
    `/${PAGE_FOLDERS.checkout}/tazapay_return`,
    `/${PAGE_FOLDERS.products}`,
];

// Checkout Sub-Paths
export const CHECKOUT_SUB_PATHS = [
    `/${PAGE_FOLDERS.checkout}/paypal_capture`,
    `/${PAGE_FOLDERS.checkout}/payment_status`,
    `/${PAGE_FOLDERS.checkout}/threeds`,
    `/${PAGE_FOLDERS.checkout}/sofort`,
    `/${PAGE_FOLDERS.checkout}/ideal`,
    `/${PAGE_FOLDERS.checkout}/return_inicis`,
    `/${PAGE_FOLDERS.checkout}/tazapay_return`,
];

// Page Paths
export const ENTERPRISE_LANDING_ABM = `/${PAGE_FOLDERS.enterprise}/ads-branding-marketing/`;
export const ENTERPRISE_LANDING_IT = `/${PAGE_FOLDERS.enterprise}/information-technology/`;
export const ENTERPRISE_LANDING_GDVA = `/${PAGE_FOLDERS.enterprise}/graphic-design-visual-arts/`;
export const ENTERPRISE_LANDING_EDU = `/${PAGE_FOLDERS.enterprise}/education/`;
export const ENTERPRISE_LANDING_HW = `/${PAGE_FOLDERS.enterprise}/healthcare-wellness/`;
export const ENTERPRISE_LANDING_API = `/${PAGE_FOLDERS.enterprise}/api-partners/`;
export const ENTERPRISE_LANDING_OTHERS = `/${PAGE_FOLDERS.enterprise}/others/`;
export const MY_ACCOUNT_PATH = '/myaccount';
export const PRODUCTS_PATH = `/${PAGE_FOLDERS.products}/`;
export const ENTERPRISE_PATH = `/${PAGE_FOLDERS.enterprise}`;
export const PAYMENT_SUCCESS_PATH = `/${PAGE_FOLDERS.checkout}/payment_status?success=1`;
export const PAYMENT_NO_SUCCESS_PATH = `/${PAGE_FOLDERS.checkout}/payment_status?success=0`;
export const PAYMENT_THREEDS_PATH = '/threeds';
export const PAYMENT_IDEAL_PATH = '/ideal';
export const PAYMENT_INICIS_RETURN_PATH = '/return_inicis';
export const INICIS_RETURN_PATH = `${PAGE_FOLDERS.checkout}/return_inicis`;
export const PAYPAL_CAPTURE_PATH = `/${PAGE_FOLDERS.checkout}/paypal_capture`;
export const INVOICE_HISTORY_PATH = '/myaccount/?mypage=paymentinfo&tab=purchasehistory';
export const USER_PROFILE_PATH = '/myaccount/?mypage=profile&tab=personalinformation';
export const LOGIN_PATH = '/login/';
export const CONTACT_US_PATH = '/contact/';
export const PLUS_LANDING_URL = '/plus';
export const PLUS_PRICING_URL = '/plus/pricing';
export const FREE_IMAGES_URL = '/free-images';
export const XPAY_CHECKOUT_PATH = '/checkout-v2/xpay/xpay_payment_request.php';
export const PAYMENT_SUCCESS_PLUS_PATH =
    '/plus/?utm_source=PLUS_PaymentSuccess&utm_medium=StartBrowsing&utm_campaign=PLUS_Button';
// export const AI_IMAGE_PREVIEW_PATH = process.env.APP_ENV.toLowerCase() !== 'development' ? 'https://ai-previews.123rf.com/ai-txt2img/preview/' : 'https://assets-dev.123rf.com/ai-txt2img/preview/';
export const AI_IMAGE_PREVIEW_PATH = 'https://ai-previews.123rf.com/ai-txt2img/preview/';
export const AI_IMAGE_PREVIEW_PATH_DEV = 'https://assets-dev.123rf.com/ai-txt2img/preview/';

// Page Origins
export const PAGE_ORIGINS = {
    pricing: 'pricing',
    details: 'details',
    homepage: 'homepage',
    serp: 'serp',
    imageDetail: 'image_detail',
    detailsPopup: 'details_popup',
    plusLanding: 'plus_landing',
    deals: 'deals',
    aiImage: 'ai_image',
};
export const VALID_PAGE_ORIGINS = [
    PAGE_ORIGINS.pricing,
    PAGE_ORIGINS.details,
    PAGE_ORIGINS.homepage,
    PAGE_ORIGINS.serp,
    PAGE_ORIGINS.imageDetail,
    PAGE_ORIGINS.detailsPopup,
    PAGE_ORIGINS.plusLanding,
    PAGE_ORIGINS.deals,
    PAGE_ORIGINS.aiImage,
];
export const NORM_PAGE_ORIGINS = [
    PAGE_ORIGINS.pricing,
    PAGE_ORIGINS.homepage,
    PAGE_ORIGINS.serp,
    PAGE_ORIGINS.imageDetail,
    PAGE_ORIGINS.detailsPopup,
    PAGE_ORIGINS.plusLanding,
    PAGE_ORIGINS.deals,
    PAGE_ORIGINS.aiImage,
];

// Promo Banner Links
// export const PIXLR_PROMO_LINK =
//     'https://pixlr.com/promo/nineninety/?utm_source=123rf&utm_medium=banner-purchase-successful&utm_campaign=nineninety';
// export const DESIGNS_AI_PROMO_LINK =
//     'https://designs.ai/en/videomaker?utm_source=123rf&utm_medium=banner-purchase-successful&utm_campaign=Videomaker';

// Exclude redirect to pricing page
export const EXCLUDE_REDIRECT_PATHS = [
    PAYMENT_THREEDS_PATH,
    PAYMENT_IDEAL_PATH,
    PAYMENT_INICIS_RETURN_PATH,
];

// Payment Status Codes
export const PAYMENT_STATUS = { success: '1', nosuccess: '0' };

// // Support Languages
// export const SUPPORTED_HREF_LANGS = [
//     { lang: 'br', hrefLang: 'br' },
//     { lang: 'cz', hrefLang: 'cs' },
//     { lang: 'de', hrefLang: 'de' },
//     { lang: 'en', hrefLang: 'en' },
//     { lang: 'es', hrefLang: 'es' },
//     { lang: 'fr', hrefLang: 'fr' },
//     { lang: 'gb', hrefLang: 'zh-Hans' },
//     { lang: 'hu', hrefLang: 'hu' },
//     { lang: 'it', hrefLang: 'it' },
//     { lang: 'jp', hrefLang: 'ja' },
//     { lang: 'kr', hrefLang: 'ko' },
//     { lang: 'nl', hrefLang: 'nl' },
//     { lang: 'pl', hrefLang: 'pl' },
//     { lang: 'pt', hrefLang: 'pt' },
//     { lang: 'ru', hrefLang: 'ru' },
//     { lang: 'tr', hrefLang: 'tr' },
//     { lang: 'tw', hrefLang: 'zh-Hant' },
// ];

export const USER_PURCHASE_STATUS = {
    buyer: 1,
    nonBuyer: 0,
};

// Subscription Activation Options
export const SUBSCRIPTION_ACTIVATION = {
    now: 'now',
    after: 'after',
    on: 'on',
};

// State Defaults
export const DEFAULT_STATE_TEXT_VALUE = {
    paymentMethodKR: process.env.KGINICIS_PAYMENT_METHOD === 'OFF' ? 'xpay' : 'inicis',
    paymentMethodCard: 'creditcard',
    uniformInvoiceType: 2,
    donateMultiCarrier: '',
    donateLoveCode: '1012833',
    subscribeOption: 'now',
};

// List of Modal Forms
export const MODAL_FORM = {
    signup: 'signup',
    signin: 'signin',
    forgotPassword: 'forgot-password',
    signupinprogress: 'signupinprogress',
};

// List of HTTP status code
export const HTTP_STATUS_CODE = {
    ok: 200,
    created: 201,
};

export const DISABLE_LEAVE_SITE_CONFIRM_IDS = [
    'simpleheader-logo',
    'simpleheader-link-support',
    'react-select-header-langpopup-dropdown-input',
    'checkout-submit',
    'simple-footer-email',
    'simple-footer-contact',
    'agreementform-tnc-privacy-agree',
    'login-button',
    'pay-gateway-ideal-container',
    'pay-gateway-ideal',
    'pay-gateway-inicis',
    'pay-gateway-inicis-container',
    'pay-gateway-lg-dacom-container',
    'pay-gateway-lg-dacom',
    'refresh-page-button',
    'modal-manage-plan-btn',
    'view-premium-plans',
    'premium-upsell-modal-closeButton',
    'iframe',
];

export const A2M_COUNTRIES = ['US', 'UK', 'GB', 'FR'];

export const AMERICA_NY_TZ = 'America/New_York';

export const SIMPLE_ANSWER = { no: 0, yes: 1, maybe: 2 };

export const DEFAULT_IP_ADDRESS = '192.168.1.1';

export const USER_CHECK_STATUS = { ok: 'ok', failed: 'failed' };

// List of Inicis Payment Method
export const INICIS_PM = {
    virtualBank: 'INCVB',
    creditCard: 'INCCard',
    bankTransfer: 'INCDB',
};